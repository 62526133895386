nav#site-navigation {
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  /*position: fixed;*/
  -ms-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: 0 30px;
  margin-bottom: 2px;
}

nav#site-navigation .site-nav-left {
  vertical-align: middle;
  position: relative;
  /*        z-index: 9;*/
  /*background-color: #fefefe;*/
  padding-top: 5px;
}

nav#site-navigation .site-nav-right {
  vertical-align: middle;
  position: relative;
  /*        z-index: 9;*/
  /*background-color: #fefefe;*/
}

nav#site-navigation .logo {
  position: relative;
  width: 145px;
}

nav#site-navigation .logo a {
  display: block;
  height: 65px;
}

nav#site-navigation .logo img {
  margin-top: 20px;
}

nav#site-navigation .menu-container {
  display: block;
}

nav#site-navigation ul.menu {
  margin: 0;
  list-style: none;
}

nav#site-navigation ul.menu li.menu-item {
  float: left;
  margin: 0 10px;
}

nav#site-navigation ul.menu li.menu-item:last-child {
  margin-right: 0;
}

nav#site-navigation ul.menu li.menu-item a {
  color: #4e4e4e;
  transition: 0.2s;
  padding: 0 10px;
  font-size: 15px;
  line-height: 80px;
  display: block;
  position: relative;
  text-decoration: none;
  font-weight: 500;
}

nav#site-navigation ul.menu li.menu-item a:after {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  content: "";
  background-color: #00559f;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-out;
}

nav#site-navigation ul.menu li.menu-item a:hover,
nav#site-navigation ul.menu li.menu-item.current-menu-ancestor a,
nav#site-navigation ul.menu li.menu-item a.current-menu-item {
  color: #4e4e4e;
  transition: 0.2s;
}

nav#site-navigation ul.menu li.menu-item a:hover:after,
nav#site-navigation ul.menu li.menu-item.current-menu-ancestor a:after,
nav#site-navigation ul.menu li.menu-item a.current-menu-item:after {
  opacity: 1;
  visibility: visible;
  transition: all 0.2s ease-out;
}

nav#site-navigation ul.sub-menu:hover ul.navbar-nav > li > a:after {
  opacity: 1;
  visibility: visible;
  transition: all 0.2s ease-out;
}
