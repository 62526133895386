$dark-blue: #065da8;
$green: #5cb85c;
$dark-green: #3c763d;
$light-green: #b8e0b8;

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: 100px;
  display: flex;
  justify-content: center;
}

.dropzone-dragover {
  border-color: $dark-green;
  background-color: $light-green;
}

.dropzone-success {
  border-color: $dark-green;
  background-color: $light-green;
  color: black;
  font-size: 16px;
}

.dropzone.disabled {
  opacity: 0.6;
}
