body {
  background-color: #f8f8f8;
}

.loading {
  opacity: 0.3;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.footer-legal {
  background-color: #004a8b;
  color: rgba(254, 254, 254, 0.6);
  padding: 20px 0;
  font-size: 0.8125rem;
}

.footer-legal a {
  color: rgba(254, 254, 254, 0.6);
}

.pb-3 {
  padding-bottom: 25px;
}

.pt-3 {
  padding-bottom: 25px;
}
